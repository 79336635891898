import * as React from "react";

import { CategoryDivision, CategoryDivisionDisplayName } from "../../models/CategoryDivision";
import { CellContext, ColumnDef, ColumnDefBase, ExpandedState, RowData, SortingState, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { DefaultButton, FontIcon, Icon, IconButton, PrimaryButton, SpinButton, Spinner, Stack, TextField } from "@fluentui/react";
import { FiltersAction, FiltersView } from "../../reducers/filterReducer";
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { clearEmptyBudgets, getBudgetFiscalYear, getBudgetId, getLastFiscalYear, recalculateServiceBudget, suppleBudgets } from "../../utils/BudgetUtils";
import { flatten, get, has, isEmpty, orderBy, sortBy, sortedUniq, sum, toNumber, uniq } from "lodash";
import moment, { now } from "moment";
import { useGetCostAndBudget, useGetLatestBudgetVersion, useGetServiceLevelBudget, useGetSubmitOverview, useInitializeNewFinOpsBudgets } from "../../hooks/useFinOpsBudget";

import { BudgetLayout } from "./BudgetLayout";
import { Endpoints } from "../../utils/Constants";
import { IAppState } from "../../store";
import { IFinOpsBudget } from "../../models/FinOpsBudgets";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import { ServiceTreeLevel } from "../../models/serviceTree";
import WorkloadFilter from "../common/FiltersBanner/WorkloadFilter/WorkloadFilter";
import { currencyFormatter } from "../../utils/currency";
import styles from "./Budget.less";
import { useCategoryFilters } from "../../hooks/useFilters";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

declare module '@tanstack/react-table' {
    interface TableMeta<TData extends RowData> {
      updateData: (rowId: string, columns: Record<string, unknown>) => void
    }

    interface ColorKeyColumnBase<TData extends RowData, TValue = unknown> extends ColumnDefBase<TData, TValue> {
        headerStyles: React.CSSProperties;
    }
}
  
const numberCellFormatter = (cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    if (cell.row.depth === 0) {
        if (cell.column.id === 'increaseRate') return cell.getValue();
        if (cell.column.id === 'costBudget') return currencyFormatter(toNumber(cell.getValue()));
        if (cell.column.id === 'quantityBudget') return cell.getValue() ? Math.round(toNumber(cell.getValue())) : "";
    }
    const updateTable = (rowId: string, columnId: string, value: number) => {
        let newIncreaseRate = 0;
        let newQuantityBudget = undefined;
        let newCostBudget = 0;
        if (columnId === 'increaseRate') {
            newIncreaseRate = value;
            newQuantityBudget = cell.row.original.lastYearQuantity && cell.row.original.lastYearQuantity * (100 + value) / 100;
            newCostBudget = cell.row.original.lastYearCost * (100 + value) / 100;
        } else if (columnId === 'quantityBudget') {
            newIncreaseRate = cell.row.original.lastYearQuantity ? (value - cell.row.original.lastYearQuantity) / cell.row.original.lastYearQuantity * 100 : 0;
            newQuantityBudget = value;
            newCostBudget = cell.row.original.lastYearQuantity ? (newIncreaseRate + 100) * cell.row.original.lastYearCost / 100 : cell.row.original.costBudget;
        } else if (columnId === 'costBudget') {
            newIncreaseRate = cell.row.original.lastYearCost ? (value - cell.row.original.lastYearCost) / cell.row.original.lastYearCost * 100 : 0;
            newQuantityBudget = cell.row.original.lastYearCost ? cell.row.original.lastYearQuantity && cell.row.original.lastYearQuantity * (100 + newIncreaseRate) / 100 : cell.row.original.quantityBudget;
            newCostBudget = value;
        } else {
            return;
        }
        cell.table.options.meta?.updateData(rowId, {
            increaseRate: newIncreaseRate.toFixed(2),
            quantityBudget: newQuantityBudget,
            costBudget: newCostBudget
        });
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState<string | number | undefined>(Math.round(toNumber(initialValue || 0)));
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return (
        <SpinButton
            styles={{ spinButtonWrapper: cell.column.id === 'costBudget' ? styles.costSpinButton : undefined }}
            value={formatNumberCell(cell.column.id, value).toString()}
            step={cell.column.id === 'increaseRate' ? 0.1 : 100}
            onChange={(_e, value) => value && updateTable(cell.row.id, cell.column.id, toNumber(value))}
        />
    );
};

const textCellFormatter = (cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    if (cell.row.depth === 0) return cell.getValue();
    const updateTable = (rowId: string, columnId: string, value: string) => {
        cell.table.options.meta?.updateData(rowId, {
            [columnId]: value
        });
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState(initialValue);
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return (
        <TextField
            multiline
            value={(value || '').toString()}
            onChange={(_e, value) => value && updateTable(cell.row.id, cell.column.id, value)}
        />
    );
};

const CURRENT_BUDGET_FISCAL_YEAR = getBudgetFiscalYear();

const HiddenServiceList = ['e80e7019-1532-4b97-bcd9-235b07388876', //M365 Passive Monitoring',
                            'c7d6dbf7-31a4-4b35-ba59-efc48b9e9ffd',//'Cosmic Dev-Debug'
                            '478ae0ce-50f8-44e7-99a6-cf4b9b40d97e',//'COSMIC Platform'
                            ];

export const Budget: React.FC = () => {
    const [editing, setEditing] = React.useState(false);

    const selectedOwners = useCategoryFilters().filters.filters.Owner;
    const currentOwner = selectedOwners && selectedOwners.length > 0 ? selectedOwners[0] : undefined;
    const {data: initialData, isLoading} = useGetServiceLevelBudget({
      fiscalYear: CURRENT_BUDGET_FISCAL_YEAR,
      serviceGM: currentOwner
    });
    const [modifiedRows, setModifiedRows] = React.useState(new Set());

    const filteredData = React.useMemo(() => {
        return initialData?.filter(service => !HiddenServiceList.includes(service.serviceId)) || [];
    }, [initialData]);    

    const columns = React.useMemo<ColumnDef<IFinOpsBudget, number | string>[]>(
        () => {
            let baseColumns: ColumnDef<IFinOpsBudget, number | string | undefined>[] = [
                {
                    id: 'service',
                    header: 'Service',
                    accessorFn: row => serviceTree.indexMap.get(row.serviceId)?.n || row.serviceId || '',
                    footer: 'Total',
                    columns: [
                        {
                            id: 'category',
                            header: 'Category',
                            accessorKey: 'category',
                            cell: ({row, getValue}) => (
                                <>
                                {getValue()}
                                </>
                            ),
                        },
                        {
                            id: 'type',
                            header: 'Type',
                            accessorKey: 'type',
                        },
                    ]
                },
                {
                    id: 'lastYearCost',
                    header: `${getLastFiscalYear(CURRENT_BUDGET_FISCAL_YEAR)} Spend`,
                    accessorKey: 'lastYearCost',
                    cell: cell => currencyFormatter(toNumber(cell.getValue())),
                    footer: ({table}) => {
                        return currencyFormatter(sum(table.getFilteredRowModel().rows.map(row => row.original.lastYearCost)));
                    }
                },
                {
                    id: 'lastYearQuantity',
                    header: `${getLastFiscalYear(CURRENT_BUDGET_FISCAL_YEAR)} Quantity`,
                    accessorKey: 'lastYearQuantity',
                    cell: cell => cell.getValue() ? Math.round(toNumber(cell.getValue())).toLocaleString() : "",
                },
                {
                    id: 'unit',
                    header: 'Unit',
                    accessorKey: 'unit',
                },
                {
                    id: 'blendedRate',
                    header: 'Blended Rate',
                    accessorFn: row => {
                        if (!row.lastYearQuantity) return "";
                        let n = row.lastYearCost / (row.lastYearQuantity || 1);
                        const numberBeforeDecimal = Math.floor(n);
                        n -= numberBeforeDecimal;
                        if (n === 0) {
                            return numberBeforeDecimal;
                        }
                        return parseFloat(numberBeforeDecimal + n.toFixed(1-Math.floor(Math.log(n)/Math.log(10))));
                    },
                }];

            // Budget related info.
            baseColumns = baseColumns.concat([{
                id: 'increaseRate',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Growth Rate %`,
                accessorKey: 'increaseRate',
                cell: !editing ? cell => cell.getValue() : numberCellFormatter,
            },
            {
                id: 'costBudget',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Cost`,
                accessorKey: 'costBudget',
                cell: !editing ? cell => currencyFormatter(Math.round(toNumber(cell.getValue()))) : numberCellFormatter,
                footer: ({table}) => {
                    return currencyFormatter(sum(table.getFilteredRowModel().rows.map(row => toNumber(row.getValue('costBudget')))));
                }
            },
            {
                id: 'quantityBudget',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Quantity`,
                accessorKey: 'quantityBudget',
                cell: !editing ? cell => (cell.getValue() ? Math.round(toNumber(cell.getValue())).toLocaleString() : "") : numberCellFormatter,
            },
            {
                id: 'justification',
                header: 'Justification',
                accessorKey: 'justification',
                cell: !editing ? cell => cell.getValue() : textCellFormatter,
            }]);

            // Only show review info when not editing
            if (!editing) {
                baseColumns = baseColumns.concat(
                    [{
                        id: 'submitter',
                        header: 'Submitter',
                        accessorKey: 'submitter',
                    },
                    {
                        id: 'version',
                        header: 'Version',
                        accessorFn: row => row.version?.format('YYYY-MM-DD') || '',
                    },
                    {
                        id: 'approver',
                        header: 'Approver',
                        accessorKey: 'approver',
                    },
                    {
                        id: 'approved',
                        header: 'Status',
                        accessorKey: 'approved',
                        cell: 
                            cell => cell.getValue() == 2 ? 'Yes':
                                cell.getValue() == 1 ? 'No' :
                                cell.getValue() === 0 ? 'Not reviewed' : '',
                    },
                    {
                        id: 'approvedTime',
                        header: 'Approved Time',
                        accessorFn: row => row.approvedTime?.format('YYYY-MM-DD') || '',
                    },
                    {
                        id: 'comment',
                        header: 'Comment',
                        accessorKey: 'comment',
                        cell: cell => cell.getValue(),
                    },
                ]);
            }

            return baseColumns;
        },
        [editing]
      );
      const [data, setData] = React.useState(filteredData || []);
      React.useEffect(() => {
        setData(filteredData || []);
    }, [filteredData]);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    React.useEffect(() => {
        if (editing) {
            let newData: IFinOpsBudget[] = data.map(serviceLevelBudget => ({
                ...serviceLevelBudget,
                children: suppleBudgets(CURRENT_BUDGET_FISCAL_YEAR, serviceLevelBudget.serviceId, serviceLevelBudget.children || [])
            }));

            if (!isEmpty(sorting)) {
                const columnId = sorting[0].id as keyof IFinOpsBudget;
                const desc = sorting[0].desc;
                newData = orderBy(
                    newData.map(serviceLevelBudget => {
                        serviceLevelBudget.children = serviceLevelBudget.children &&
                            orderBy(serviceLevelBudget.children, s => s[columnId], desc ? 'desc' : 'asc');

                        return serviceLevelBudget;
                    }),
                    s => s[columnId],
                    desc ? 'desc' : 'asc');
            }
            setData(newData);
        } else {
            setData(data.map(serviceLevelBudget => ({
                ...serviceLevelBudget,
                children: clearEmptyBudgets(serviceLevelBudget.children)
            })));
        }
      }, [editing]);
    
      const table = useReactTable({
        data,
        columns,
        state: {
            sorting: editing ? [] : sorting,
            expanded
        },
        sortDescFirst: true,
        getRowId: getBudgetId,
        onSortingChange: setSorting,
        onExpandedChange: setExpanded,
        getSubRows: row => row.children,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        // Provide our updateData function to our table meta
        meta: {
            updateData: (rowId, columns) => {
                setData(old =>
                    old.map((serviceRow) => {
                    let row: IFinOpsBudget = {
                        ...serviceRow,
                        children: serviceRow.children?.map((budgetRow) => {
                        if (getBudgetId(budgetRow) === rowId) {
                            setModifiedRows(prev => new Set(prev).add(rowId));
                            return {
                            ...budgetRow,
                            ...columns
                            }
                        }
                        return budgetRow;
                        })
                    };
                    if (has(columns, 'increaseRate') || has(columns, 'quantityBudget') || has(columns, 'costBudget')) {
                        row = recalculateServiceBudget(row);
                    }
                    return row;
                    })
                )},
        },
      });

      /*React.useEffect(() => {
      const savedModifiedRows = localStorage.getItem('modifiedRows');
      const savedModifiedData = localStorage.getItem('modifiedData');
      const savedEditingState = localStorage.getItem('isEditing');

      if (savedModifiedRows) {
          setModifiedRows(new Set(JSON.parse(savedModifiedRows)));
      }
      if (savedModifiedData) {
          setData(JSON.parse(savedModifiedData));
      }
      if (savedEditingState) {
          setEditing(JSON.parse(savedEditingState));
      }
      }, []);*/

      const ownerFilter = CategoryDivision.Owner;
      const serviceTree = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
      const ownerList = React.useMemo(() =>
      uniq(sortBy(Array.from(serviceTree.owners.keys()).filter(x => x).concat(['martri', 'nmyhre', 'rakalapa', 'skini']))),
      [serviceTree.owners]);
  
      const queryClient = useQueryClient();
      const mutation = useInitializeNewFinOpsBudgets(
      () => {
          queryClient.invalidateQueries([Endpoints.GetCostAndBudget]);
          alert('Submitted successfully');
          setEditing(false);
      },
      () => {
          alert('Failed to submit');
      }
      );
  
      /*
      const handleSave = () => {
      const modifiedData = flatten(data.map(serviceData => clearEmptyBudgets(serviceData.children) || []))
          .filter(b => modifiedRows.has(getBudgetId(b)) || !b.version)
          .map(b => ({
              ...b,
              id: 0,
          }));
      mutation.mutate(modifiedData, {
          onSuccess: () => {
              setModifiedRows(new Set());
              localStorage.removeItem('modifiedRows');
              localStorage.removeItem('modifiedData');
              localStorage.removeItem('isEditing');
              queryClient.invalidateQueries(['serviceLevelBudget', CURRENT_BUDGET_FISCAL_YEAR, currentOwner]);
          }
      });
      };
      
      const handleDiscard = () => {
          setModifiedRows(new Set());
          localStorage.removeItem('modifiedRows');
          localStorage.removeItem('modifiedData');
          localStorage.removeItem('isEditing');
          setEditing(false);
      };
  
      const handleEditToggle = (isEditing: boolean) => {
      setEditing(isEditing);
      localStorage.setItem('isEditing', JSON.stringify(isEditing));
      };*/
  
      return (
        <BudgetLayout>
            <h1>Submit Ask</h1>
            <Stack horizontal tokens={{childrenGap: 20}}>
            <WorkloadFilter
                key={ownerFilter}
                category={ownerFilter}
                optionList={ownerList}
                displayName={CategoryDivisionDisplayName[ownerFilter]}
                isSingleSelect
            />
            {
                currentOwner && !editing ? (
                <PrimaryButton
                    text="Create Ask"
                    onClick={() => setEditing(true)}
                />
            ) : (
                currentOwner && (
                    mutation.isLoading ? (
                        <Spinner label='Saving ...' labelPosition="right" />
                    ) : (
                        <>
                            <PrimaryButton
                                text="Save Ask"
                                disabled={mutation.isLoading}
                                onClick={() => {
                                    const modifiedData = flatten(data.map(serviceData => clearEmptyBudgets(serviceData.children) || []))
                                    .filter(b => modifiedRows.has(getBudgetId(b)) || !b.version)
                                    .map(b => ({
                                    ...b,
                                    id: 0,
                                    }));
                                    mutation.mutate(modifiedData, {
                                        onSuccess: () => {
                                            // Reset modifiedRows and fetch the latest data
                                            setModifiedRows(new Set());
                                            queryClient.invalidateQueries(['serviceLevelBudget', CURRENT_BUDGET_FISCAL_YEAR, currentOwner]);
                                        }
                            });}} />
                            <DefaultButton
                                disabled={mutation.isLoading}
                                onClick={() => setEditing(false)}
                                text="Discard Changes" />
                        </>
                    )
                )
            )}
            </Stack>
            {!currentOwner && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    Please select an owner to proceed.
                </div>
            )}
            {currentOwner && <Table stickyHeader>
                <TableHead>
                    {
                        table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header => (
                                        headerGroup.depth > 0 && !['category', 'type'].includes(header.column.id) ?
                                        undefined :
                                        <TableCell key={header.id} colSpan={header.colSpan} align="center"
                                            rowSpan={headerGroup.depth === 0 && header.column.id !== 'service' ? 2 : 1}
                                            className={header.column.id == 'category' ? styles.categoryHeader :
                                                header.column.id == 'type' ? styles.typeHeader :
                                                header.column.id == 'service' ? styles.serviceHeader :
                                                header.column.id == 'justification' ? styles.justificationHeader : styles.tableHeader
                                            }
                                            onClick={editing ? undefined : header.column.getToggleSortingHandler()}
                                            style={{cursor: editing ? 'auto' : 'pointer'}}>
                                            {(
                                                flexRender(header.column.columnDef.header, header.getContext())
                                            )}
                                            {
                                                {
                                                    asc: <FontIcon iconName='SortUp'/>,
                                                    desc: <FontIcon iconName='SortDown'/>
                                                }[header.column.getIsSorted() as string] ?? null
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map(row => {
                    return (
                        <TableRow key={row.id} className={styles.tableRow}>
                        {row.getVisibleCells().map(cell => {
                            if (row.depth === 0 && cell.column.id === 'type') {
                                return undefined;
                            }
                            const isServiceCell = (row.depth === 0 && cell.column.id === 'category');
                            return (
                            <TableCell key={cell.id} align={getColumnAlignment(cell.column.id)}
                                colSpan={isServiceCell ? 2 : 1}
                                className={(cell.column.id == 'category') ?
                                    isServiceCell ? styles.serviceColumn :
                                    styles.categoryColumn : cell.column.id == 'type' ? styles.typeColumn : ''
                                }>
                                {
                                    isServiceCell ?
                                    <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10}}>
                                    <IconButton
                                        iconProps={{ iconName: row.getIsExpanded() ? 'ChevronDown' : 'ChevronRight' }}
                                        onClick={row.getToggleExpandedHandler()} />
                                    {serviceTree.indexMap.get(row.original.serviceId)?.n ?? ""}
                                    </Stack> :
                                    flexRender(cell.column.columnDef.cell,cell.getContext())
                                }
                            </TableCell>
                            )
                        })}
                        </TableRow>
                    )
                    })}
                </TableBody>
                <TableFooter>
                    {
                        table.getFooterGroups().slice(1).map(footerGroup => (
                            <TableRow key={footerGroup.id} className={styles.tableFooter}>
                                {
                                    footerGroup.headers.map(header => (
                                        <TableCell align={getColumnAlignment(header.column.id)} key={header.id} colSpan={header.colSpan}
                                            className={header.column.id == 'service' ? styles.serviceFooter : ''
                                            }>
                                            {(
                                                flexRender(header.column.columnDef.footer, header.getContext())
                                            )}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableFooter>
            </Table>}
        </BudgetLayout>
      )
}

function getColumnAlignment(columnId: string) {
    switch (columnId) {
        case 'costBudget':
        case 'quantityBudget':
        case 'lastYearCost':
        case 'lastYearQuantity':
        case 'increaseRate':
        case 'blendedRate':
        case 'unit':
            return 'right';
        default:
            return 'left';
    }
}

function formatNumberCell(columnId: string, value?: string | number) {
    if (!value) return 0;

    const formatedValue = toNumber(value);
    if (columnId === 'increaseRate') {
        return parseFloat(formatedValue.toFixed(2));
    }
    if (columnId === 'costBudget' || columnId === 'quantityBudget') {
        return Math.round(formatedValue);
    }

    return formatedValue;
}